$theme-colors: (
    "primary": #11284F,
    "dark": #000000
);

// var colore
$primary: #11284F;
$grey: #666666;

// Colori animazione
$colorOne: #F6EFEC;
$colorTwo:#F8E9EE;
$colorThree:#F8E6F2;
$colorFour:#E8E9FC;
$colorFive:#DFEFFB;
$colorSix:#E8F3FA;
$colorSeven:#E7F6F5;
$colorHeight:#F6F5E7;


@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,900;1,300&display=swap');

// Reset 
section, .extra-margin {
    padding-top: 80px;
    padding-bottom: 80px;
}
iframe {
    border-radius: 25px;
}

// Color
.primary-color {
    color: #11284F;
}
.organge {
    color: #FE9B5F;
}
.bg-light-blue {
    background-color: #F4F8FB;
}
.pos-relative {
    position: relative;
}
.footer {
    margin-top:3rem;
}

p {
    font-size: 18px;
    line-height: 30px;
    color: #666666;
}

.blue-shadow {
    box-shadow: 0 0 35px rgba(175, 221, 255, .7);
}

// Hero elmenti
.hero-element-bg {
    position: absolute;
    width: 440px;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    z-index: 1;
}

// .navbar {
//     border-bottom:1px solid #eee;
// }

body, p {
    font-family: 'Lato', sans-serif;
}
h1, h2, h3, h4 {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
}
h1 {
    font-size: 60px;
}
h2 {
    font-size: 48px;
}
.index-top {
    z-index: 99;
}

// My code

// Navbar
nav {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
}

.top-hero {
    background-color: #11284F;
    height: 100vh;
}

// Sectionc element 
.counter {
    font-family: 'Josefin Sans', sans-serif;
    padding: 50px 20px;
    background-color: white;
    border-radius: 30px;
    margin-top: 100px;
    margin-bottom: 100px;
}
.number-counter {
    font-size: 60px;
    font-weight: 700;
}
.special-number {
    color: #009FF6;
}
.name-counter {
    font-size: 20px;
    color: $grey;
    margin-top: 0;
}


// Button
.btn-primary {
    background-image: linear-gradient(to right, #009DF5, #6740DC);
    display: block;
    width: 220px;
    height: 60px;
    border-radius: 100px;
    line-height: 60px;
    text-decoration: none;
    font-size: 20px;
}
.btn-flat {
    background-color:#0BA2F6;
    // display: block;
    // width: 180px;
    padding: 15px 35px;
    height: 50px;
    border-radius: 100px;
    line-height: 50px;
    text-decoration: none;
    font-size: 18px;
    color: white;
    transition: all .3s ease-in-out;
    &:hover {
        background-color: white;
        box-shadow: 0 0 35px rgba(175, 221, 255, .7);
        color: #0BA2F6;
    }
}

// griglia loghi
.grid-loghi {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    grid-gap: 25px;
    .logo {
        width: auto;
        height: auto;
        img {
            max-width: 100%;
            height: 75px;
            width: auto;
            filter: grayscale(100);
            transition: all .2s ease-in-out;
            opacity:.5;
            &:hover {
                filter: none;
                opacity: 1;
            }
        }
    }
}


// Testimonial 
.text-testimonial {
    width: 80%;
    text-align: left;
    p {
        font-size: 22px;
        line-height: 36px;
        color: #666;
    }
    @media (max-width:576px) {
        text-align: center;
    }
}
.thumb-testimonail {
    width: 20%;
    .cicle-avatar {
        width: 100%;
        border-radius: 100pc;
        max-width: 125px;
    }
}

.virgolette {
    position: absolute;
    width: 225px;
    top: 0;
    z-index: 1;
}


// Sezione illustrazione e animazione
.illustration-animated {
    padding: 55px 0;
}
.illustration-move {
    width: auto;
    max-height: 800px;
    margin: 0 auto;
    display: block;
}

.animated-section-color {
    height: 100vh;
    display: flex;
    align-items: center;
}
.container-card-section {
    padding: 50px;
    border-radius: 25px;
    box-shadow: 0 0 15px rgba(0,0,0,.1);
    background-color: white;
    position: relative;
    .pre-title {
        text-transform: uppercase;
        font-weight: 700;
        font-family: 'Josefin Sans', sans-serif;
    }
    img {
        width: 280px;
        position: absolute;
        right: -20px;
        top: -140px;
    }
}

#sectionOne {
    background-color: $colorOne;
}

// pre footer
.pre-footer {
    border-radius: 25px;
    background-color: $primary;
    color: white;
    position: relative;
    min-height: 450px;
    padding: 80px;
    margin-top: 180px;
    h2 {
        font-size: 70px;
    }
    .sub-title {
        font-size: 34px;
        font-weight: 400;
        font-family: 'Josefin Sans', sans-serif;
    }
}
.pre-footer-element-bg {
    position: absolute;
    right: 50px;
    top: -140px;
    width: 560px;
}